import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserQuestionComponent } from './component/user-question/user-question.component';
import { BackSubmitCancelButtonComponent } from './component/back-submit-cancel-button/back-submit-cancel-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomadapterService } from './service/customadapter.service';
import { CustomDateParserFormatterService } from './service/custom-date-parser-formatter.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubmissionScreenComponent } from './component/submission-screen/submission-screen.component';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    routingComponents,
    UserQuestionComponent,
    BackSubmitCancelButtonComponent,
    SubmissionScreenComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomadapterService },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatterService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
