import { AbstractControl, ValidatorFn } from '@angular/forms';

export function checkYear(control: AbstractControl): { [key: string]: any } | null {
    const currentYear = new Date().getFullYear();

    if (control.value > currentYear) {
        return { 'validateYear': { value: control.value } }
    } else if (control.value < 1960) {
        return { 'validateYear': { value: control.value } }
    }
    else return null;
}

export function validateStartEndDate(startDate: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const startDateValue = control.parent?.get(startDate)?.value;
        const endDateValue = control.value;

        if (!startDateValue || !endDateValue) {
            return null;
        }

        const strtDate = parseDateString(startDateValue);
        const endDate = parseDateString(endDateValue);

        if (endDate < strtDate) {
            return { 'endDateValidator': { value: 'Invalid End date' } }
        } else {
            return null;
        }
    };
}

/* export function minimumYearCheck(control: AbstractControl): { [key: string]: any } | null {
    if (control.value >= 1980 || control.value === '') {
        return null;
    }
    else return { 'minimumYearCheck': { value: control.value } }
} */

function parseDateString(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(`${year}-${month}-${day}`);
}
