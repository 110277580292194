import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeScreenComponent } from './component/home-screen/home-screen.component';
import { UserQuestionComponent } from './component/user-question/user-question.component';
import { SubmissionScreenComponent } from './component/submission-screen/submission-screen.component';

const routes: Routes = [
  { path: '', component: HomeScreenComponent },
  { path: 'expertise-question', component: UserQuestionComponent },
  { path: 'confirmation', component: SubmissionScreenComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [
  HomeScreenComponent,
  UserQuestionComponent,
  SubmissionScreenComponent,
];
