import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertnotifierService {
  //easeTime: number = 500;

  constructor(private toastr: ToastrService) {}

  showSuccess(message: any, title: any) {
    this.toastr.success(message, title, {
      easing: 'ease-in',
    });
  }
  showError(message: any, title: any) {
    this.toastr.error(message, title, {
      easing: 'ease-in',
    });
  }
  showWarning(message: any, title: any) {
    this.toastr.warning(message, title, {
      easing: 'ease-in',
    });
  }
  showInfo(message: any, title: any) {
    this.toastr.info(message, title, {
      easing: 'ease-in',
    });
  }
}
