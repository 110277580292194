import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDataService } from '../../service/form-data-storage/form-data.service';
import { of } from 'rxjs';
import { DataService } from '../../service/data-transfer/data-service.service';
import { AlertnotifierService } from 'src/app/service/alertnotifier.service';
import { FacultyDetails } from 'src/app/dto/faculty-details';
import { Expertise } from 'src/app/dto/expertise-questions';
import { DateFormatterService } from 'src/app/service/date-formatter.service';

@Component({
  selector: 'app-user-question',
  templateUrl: './user-question.component.html',
  styles: [],
})
export class UserQuestionComponent implements OnInit {
  faTrash = faTrash;

  userInfo?: any;
  expertiseQuestion!: FormGroup;
  spreadSheetCounter: number = 0;
  accountingCounter: number = 0;
  finStatementCounter: number = 0;
  msPowerBICounter: number = 0;
  finModellingCounter: number = 0;
  businessValuationCounter: number = 0;
  projCorpFinCounter: number = 0;
  macroEcoAnalysisCounter: number = 0;
  questionSubSectionCounter: number = 0;
  totalFilteredQuestions: number = 0;
  totalFilteredAnswers: number = 0;
  questionNumber: number = 0;

  areaOfExpertise?: string;

  spreadSheetCompleted = false;
  accountingCompleted = false;
  finStatementCompleted = false;
  msPowerBICompleted = false;
  finModellingCompleted = false;
  businessValuationCompleted = false;
  projCorpFinCompleted = false;
  macroEcoAnalysisCompleted = false;
  isLoading: boolean = true;
  allQuestionsAnswered: boolean = false;
  // isCaptchaSuccesful: boolean = false;
  // captchaResponse: string = '';

  expertise: Expertise[] = [];
  filteredExpertise: Expertise[] = [];
  responseGroup: any[] = [];
  tempAllQuestions: any = {};
  questionAndAnswer: any = [];

  localDate: Date = new Date();
  formatedDate: string = this.dateFormatterService.formatDate(
    this.localDate.toString()
  );

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private formDataService: FormDataService,
    private dataService: DataService,
    private toastService: AlertnotifierService,
    private dateFormatterService: DateFormatterService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.formDataService.getFormData();
    if (this.userInfo === null) this.router.navigate(['/']);
    this.fetchQuestionData();

    //Only show the legend container when user scroll down to a certain point
    /* const legendContainer = document.querySelector('.legend-menu');
    let currentScrollY = window.scrollY; //target 234
    window.addEventListener('scroll', () => {
      if (currentScrollY >= 70) {
        legendContainer?.classList.remove('legend-hidden');
        console.log('Hide', currentScrollY);
      } else {
        legendContainer?.classList.add('legend-hidden');
        console.log('Don\'t hide', currentScrollY);
      }

      currentScrollY = window.scrollY;
    }); */
    //Only show the legend container when user scroll down to a certain point
  }

  fetchQuestionData() {
    of(
      this.dataService.fetchExpertiseQuestions().subscribe({
        next: (expertiseResponse) => {
          this.expertise = expertiseResponse;
          const selectedItmes = this.selectedAreaOfExpertise();
          const filteredData = this.expertise.filter((item) =>
            selectedItmes.includes(item.expertiseName)
          );
          this.filteredExpertise = filteredData;
          this.getTotalFilteredQuestions();
          this.isLoading = false;
        },
        error: (err) =>
          this.toastService.showError(err.message, 'Error Occurred'),
      })
    );
  }

  getTotalFilteredQuestions() {
    this.filteredExpertise.map((item1) => {
      item1.questionSections.map((item2) => {
        item2.questionSubSections.map((item3) => {
          this.totalFilteredQuestions += item3.questions.length;
        });
      });
    });
  }

  setUserResponse(
    questionId: number,
    optionId: number,
    sectionIndex: number,
    subSectionId: number,
    questionPerSubSection: number,
    subSectionIndex: number
  ) {
    this.totalFilteredAnswers = 0;
    if (this.responseGroup.length === 0) {
      this.responseGroup.push({
        questionId,
        optionId,
        textAnswer: '',
        email: this.userInfo.bioData.email,
        sectionIndex,
        subSectionId,
      });
    }
    if (this.responseGroup.length > 0) {
      const inputIndex = this.responseGroup.findIndex(
        (item) => item.questionId === questionId
      );
      if (inputIndex > -1) {
        this.responseGroup[inputIndex].optionId = optionId;
      }
      if (inputIndex === -1) {
        this.responseGroup.push({
          questionId,
          optionId,
          textAnswer: '',
          email: this.userInfo.bioData.email,
          sectionIndex,
          subSectionId,
        });
      }
    }

    const tempObject = {
      questionPerSubSection,
      answerPerSubSection: this.responseGroup.filter(
        (item) => item.subSectionId === subSectionId
      ).length,
      sectionIndex,
    };
    this.tempAllQuestions[subSectionId] = tempObject;
    this.questionAndAnswer = Object.values(this.tempAllQuestions).concat();
    this.questionAndAnswer.map((item: any) => {
      this.totalFilteredAnswers += item.answerPerSubSection;
    });
    this.allQuestionsAnswered =
      this.totalFilteredAnswers === this.totalFilteredQuestions;
  }

  getAnswerPerSubSection(sectionIndex: number) {
    const values = Object.values(this.tempAllQuestions).concat();
    const objectItem: any = values.filter(
      (item: any) =>
        item.sectionIndex === sectionIndex &&
        item.questionPerSubSection === item.answerPerSubSection
    );
    return objectItem.length;
  }

  totalSubQuestion(sectionIndex: number) {
    let sum = 0;
    this.filteredExpertise[sectionIndex].questionSections.map((item) => {
      sum += item.questionSubSections.length;
    });
    return sum;
  }

  get spreadSheet(): FormGroup {
    return this.expertiseQuestion.get('spreadSheet.formatting') as FormGroup;
  }

  goBack() {
    this.router.navigate(['/']);
  }

  cancelApplication() {
    this.router.navigate(['/']);
    this.formDataService.clearFormData();
  }

  /* resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    this.isCaptchaSuccesful = !this.isCaptchaSuccesful;
  } */

  /*  public isFormValid(): boolean {
     return !this.expertiseQuestion.valid;
   } */

  openVerticallyCentered(content: any, index: number, areaOfExpertise: string) {
    this.areaOfExpertise = areaOfExpertise;
    this.modalService
      .open(content, { centered: true })
      .result.then((result) => {
        if (result === 'Yes') {
          const deleteIndex = index;
          this.removeProficiency(deleteIndex);
        }
      });
  }

  openVerticallyCenteredModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /* getTotalQuestion(parentIndex: number): number {
    if (parentIndex >= 0 && parentIndex < this.expertise.length) {
      let questionSectionIndex = 0
      const questionSection = this.expertise[parentIndex].questionSections;
      if (Array.isArray(questionSection)) {
        let objectCount = 0;
        for (const item of questionSection) {
          if (typeof item === 'object' && item !== null) {
            const questionSubSection = questionSection[questionSectionIndex].questionSubSections;
            for (const item2 of questionSubSection) {
              if (typeof item2 === 'object' && item2 !== null) {
                objectCount++;
              }
            }
            questionSectionIndex++;
          }
        }
        return objectCount;
      } else {
        throw new Error(`The element at index ${parentIndex} is not an array.`);
      }
    } else {
      throw new Error(`Invalid parent index: ${parentIndex}`);
    }
  } */

  removeProficiency(sectionIndex: number) {
    this.filteredExpertise.splice(sectionIndex, 1);
    const newResponseData = this.responseGroup.filter((item: any) => {
      console.log('secindex', item.sectionIndex);
      return item.sectionIndex !== sectionIndex;
    });
    this.responseGroup = newResponseData;
    if (this.filteredExpertise.length === 0) {
      this.cancelApplication();
    }
  }

  selectedAreaOfExpertise(): string[] {
    if (this.formDataService.getFormData() !== null) {
      const exptertise = this.formDataService.getFormData().expertise;
      //Covert epertise object to array list
      const expertiseData = Object.keys(exptertise).filter(
        (item: any) => exptertise[item] !== '' && exptertise[item] !== false
      );
      let selectedAreasOfExpertise: string[] = [];
      expertiseData.forEach((item, index) => {
        switch (expertiseData[index]) {
          case 'office365':
            item = 'Microsoft 365 (MS Office)';
            break;
          case 'accounting':
            item = 'Accounting';
            break;
          case 'financialStatement':
            item = 'Financial Statement Analysis';
            break;
          case 'powerBI':
            item = 'MS Power BI';
            break;
          case 'financialModelling':
            item = 'Financial Modelling';
            break;
          case 'businessValuation':
            item = 'Business Valuation';
            break;
          case 'projectCorporateFinance':
            item = 'Project/Corporate Finance';
            break;
          case 'macroEconomicAnalysis':
            item = 'Macro-Economic Analysis';
            break;
          default:
            break;
        }
        selectedAreasOfExpertise.push(item);
      });
      return selectedAreasOfExpertise;
    }
    return [];
  }

  constructFacultyDetailData(bioDataID: number) {
    const facultyDetails: FacultyDetails = {
      bioDataID: bioDataID,
      educationalQualification: [],
      expertise: [],
      professionalQualification: [],
      workExperience: [],
    };
    let eduQuaPosition = 0;
    let proQuaPosition = 0;
    let workExpPosition = 0;

    facultyDetails.educationalQualification = this.formDataService
      .getFormData()
      .educationalQualification.map((qualification: any) => {
        eduQuaPosition++;
        return {
          degreeAwarded: qualification.degreeAwarded,
          discipline: qualification.discipline,
          institution: qualification.institution,
          year: qualification.year,
          eduQuaPosition: eduQuaPosition,
        };
      });

    facultyDetails.expertise = this.selectedAreaOfExpertise().map(
      (expertise: any) => {
        return {
          expertiseName: expertise,
        };
      }
    );

    facultyDetails.professionalQualification = this.formDataService
      .getFormData()
      .professionalQualification.map((qualification: any) => {
        proQuaPosition++;
        return {
          certification: qualification.certification,
          institution: qualification.institution,
          year: qualification.year,
          proQuaPosition: proQuaPosition,
        };
      });

    facultyDetails.workExperience = this.formDataService
      .getFormData()
      .workExperience.map((experience: any) => {
        workExpPosition++;
        if (experience.endDate === undefined) {
          experience.endDate = this.formatedDate;
        }
        return {
          current: experience.current,
          endDate: experience.endDate,
          organisation: experience.organisation,
          position: experience.position,
          startDate: experience.startDate,
          workExpPosition: workExpPosition,
        };
      });

    console.log('Fac Data-->', facultyDetails);

    of(
      this.dataService.saveFacultyDetails(facultyDetails).subscribe({
        next: (facDetailsRsp) => {
          if (facDetailsRsp) {
            this.submitResponseData();
          }
        },
        error: (err) =>
          this.toastService.showError(err.message, 'Error Occurred'),
      })
    );
  }

  submitResponseData() {
    of(
      this.dataService.saveQuestionResponse(this.responseGroup).subscribe({
        next: (rspData) => {
          if (rspData) {
            this.toastService.showSuccess(
              'Data Submitted Successfully',
              'Success'
            );
            this.router.navigate(['/confirmation']);
            this.formDataService.clearFormData();
          }
        },
        error: (err) =>
          this.toastService.showError(err.message, 'Error Occurred'),
      })
    );
  }

  submitData() {
    const bioData = {
      email: this.formDataService.getFormData().bioData.email,
      firstName: this.formDataService.getFormData().bioData.firstName,
      lastName: this.formDataService.getFormData().bioData.lastName,
      phoneNumber: this.formDataService.getFormData().bioData.telephone,
    };

    of(
      this.dataService.addBioData(bioData).subscribe({
        next: (biodataResponse) => {
          if (biodataResponse) {
            // console.log('BioData Response->', biodataResponse);
            this.constructFacultyDetailData(biodataResponse.responseData);
          }
        },
        error: (err) =>
          this.toastService.showError(err.message, 'Error Occurred'),
      })
    );
    this.allQuestionsAnswered = false;
  }
}
