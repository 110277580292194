<app-header></app-header>
<div class="content-container">
  <div class="content-header">
    <p class="content-header-text">Adjunct Faculty Application</p>
    <p id="first-parag">
      Thank you for your interest in joining Wizer Academy as an adjunct faculty member. This form will take a few
      minutes to complete and is designed to collect essential information about you to enable us better understand your
      qualifications and determine how your skills align with our requirements.
    </p>
    <!-- <p id="second-parag" class="second-parag-padding-bottom">
      Providing these details enables us to better understand your
      qualifications and determine how your skills align with our educational
      needs.
    </p> -->
  </div>

  <form class="form-parent-container " [formGroup]="userInfoData" (ngSubmit)="goToExpertiseQuestionPage()">
    <div id="form-field-container">
      <!-- <pre>{{userInfoData.value | json}}</pre> -->
      <p class="text-12 vspacer-12">
        Required<span class="required-field">*</span>
      </p>
      <p class="text-16 vspacer-30">Bio-Data</p>
      <div class="label-input-container">

        <div class="form-column-container" formGroupName="bioData">
          <div class="form-column">
            <label class="text-14">First Name<span class="required-field">*</span></label>
            <input placeholder="First Name" formControlName="firstName" type="text" class="input-text-field "
              id="first-name" name="first-name" />
            <small [class.d-none]="fName?.valid || fName?.untouched" class="text-danger">First name is required</small>
          </div>

          <div class="form-column">
            <label class="text-14">Last Name<span class="required-field">*</span></label>
            <input type="text" class="input-text-field" placeholder="Last Name" id="last-name" name="last-name"
              formControlName="lastName" />
            <small [class.d-none]="lName?.valid || lName?.untouched" class="text-danger">Last name is required</small>
          </div>

          <div class="form-column">
            <label class="text-14">Email Address<span class="required-field">*</span></label>
            <input type="text" class="input-text-field" placeholder="example@domain.com" id="email" name="email"
              formControlName="email" on-focusout="checkUserEmail(email?.value)"
              (blur)="checkUserEmail(email?.value)" />
            <div [class.d-none]="email?.valid || email?.untouched">
              <small *ngIf="email?.errors?.required" class="text-danger">Email is required</small>
              <small *ngIf="email?.errors?.email" class="text-danger">Email not valid</small>
            </div>
            <!-- <small [class.d-none]="email?.valid || email?.untouched" class="text-danger">Email is required</small> -->
          </div>

          <div class="form-column">
            <label class="text-14">Telephone Number<span class="required-field">*</span></label>
            <input type="text" class="input-text-field" placeholder="Telephone Number" id="telephone" name="telephone"
              formControlName="telephone" on-focusout="checkUserPhone(telephone?.value)"
              (blur)="checkUserPhone(telephone?.value)" />
            <small [class.d-none]="telephone?.valid || telephone?.untouched" class="text-danger">Telephone number is
              required</small>
          </div>
        </div>
        <!-- <div>Erororor</div>should use this div for error display section -->

      </div>
      <div class="border-bottom-wizer"></div>
      <!-- Work Experience -->
      <div class="label-input-container">
        <div class="header-with-button">
          <span class="text-16">Work Experience</span>
          <button [disabled]="buttonWrkExpDisable"
            [title]="buttonWrkExpDisable ? 'You have reached the maximum allowed work experience' : '' " type="button"
            (click)="addWorkExperience()" class="form-button">
            <fa-icon [icon]="faPlus" class="icon-spacer"></fa-icon>Add New
            <!-- <i class="fa fa-plus icon-spacer"></i>Add New -->
          </button>
        </div>
        <div formArrayName="workExperience">
          <div class="form-column-container" *ngFor="let workExp of workExperience.controls; let i = index;"
            [formGroupName]="i">
            <div class="form-column form-column-40">
              <label *ngIf="i == 0" class="text-14">Organisation<span class="required-field">*</span></label>
              <input type="text" [id]="i" class="input-text-field" placeholder="Organisation"
                formControlName="organisation" />
              <small
                [class.d-none]="workExperience.controls[i].get('organisation')?.valid || workExperience.controls[i].get('organisation')?.untouched"
                class="text-danger">Organisation name is required</small>
            </div>

            <div class="form-column">
              <label *ngIf="i == 0" class="text-14">Position<span class="required-field">*</span></label>
              <input type="text" [id]="i" class="input-text-field" placeholder="Position" formControlName="position" />
              <small
                [class.d-none]="workExperience.controls[i].get('position')?.valid || workExperience.controls[i].get('position')?.untouched"
                class="text-danger">Position held is required</small>
            </div>

            <div class="form-column">
              <label *ngIf="i == 0" class="text-14">Start Date<span class="required-field">*</span></label>
              <div class="date-selection-wrapper">
                <fa-icon [icon]="faCalendar" class="input-date-field-icon" (click)="d2.toggle()"></fa-icon>
                <input class="input-date-field" placeholder="dd/mm/yyyy" name="d2" formControlName="startDate"
                  ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" [minDate]="minDate" [maxDate]="maxDate"
                  readonly (dateSelect)="setEndDate(i)">
                <!--  -->
              </div>
              <small
                [class.d-none]="workExperience.controls[i].get('startDate')?.valid || workExperience.controls[i].get('startDate')?.untouched"
                class="text-danger">Start date is required</small>
            </div>

            <div class="form-column">
              <label *ngIf="i == 0" class="text-14">End Date<span class="required-field">*</span></label>
              <div class="date-selection-wrapper">
                <fa-icon [icon]="faCalendar" class="input-date-field-icon" (click)="d3.toggle()"></fa-icon>
                <!-- [disabled]="workExperience.controls[i].get('current')?.value === 'current' " -->
                <input [id]="i" class="input-date-field" placeholder="dd/mm/yyyy" name="d3" formControlName="endDate"
                  ngbDatepicker #d3="ngbDatepicker" (click)="d3.toggle()" [minDate]="minimumEndDate[i]"
                  [maxDate]="maxDate" readonly>
              </div>
              <div
                [class.d-none]="workExperience.controls[i].get('endDate')?.valid || workExperience.controls[i].get('endDate')?.untouched">
                <small *ngIf="workExp.get('endDate')?.errors?.endDateValidator"
                  class="text-danger">{{workExp.get('endDate')?.errors?.endDateValidator.value}}</small>
                <small *ngIf="workExp.get('endDate')?.errors?.required" class="text-danger">End date is required</small>
              </div>
            </div>

            <div [style.padding]="i != 0 ? '0rem 0rem 0rem 1.26rem' : null " class="radio-bin-container">
              <div class="form-column">
                <label *ngIf="i == 0" class="text-14">Current</label>
                <input type="radio" value="Yes" [id]="'current_'+i" name="current" formControlName="current"
                  (change)="onRadioChange(i)" />
              </div>
              <span *ngIf="i != 0" (click)="removeWorkExperience(i)"><img src="../../../assets/images/fi-rr-trash.svg"
                  alt=""></span>
            </div>
          </div>
        </div>

      </div>
      <div class="border-bottom-wizer"></div>
      <!-- Educational Qualification -->
      <div class="header-with-button">
        <span class="text-16">Educational Qualification</span>
        <button [disabled]="buttonEduDisable"
          [title]="buttonEduDisable ? 'You have reached the maximum allowed educational qualifications' : '' "
          (click)="addEducationalQualification()" type="button" class="form-button">
          <fa-icon [icon]="faPlus" class="icon-spacer"></fa-icon>Add New
        </button>
      </div>

      <div class="label-input-container" formArrayName="educationalQualification">
        <div class="form-column-container"
          *ngFor="let eduQualification of educationalQualification.controls; let a = index" [formGroupName]="a">
          <div class="field-container-sm">
            <div class="form-column form form-column-40">
              <label *ngIf="a == 0" class="text-14">Institution<span class="required-field">*</span></label>
              <input type="text" id="institution" class="input-text-field" placeholder="Institution"
                formControlName="institution" />
              <small
                [class.d-none]="educationalQualification.controls[a].get('institution')?.valid || educationalQualification.controls[a].get('institution')?.untouched"
                class="text-danger">Institution name is required</small>
            </div>

            <div class="form-column">
              <label *ngIf="a == 0" class="text-14">Discipline<span class="required-field">*</span></label>
              <input type="text" id="discipline" class="input-text-field" placeholder="Discipline"
                formControlName="discipline" />
              <small
                [class.d-none]="educationalQualification.controls[a].get('discipline')?.valid || educationalQualification.controls[a].get('discipline')?.untouched"
                class="text-danger">Discipline is required</small>
            </div>
            <div class="form-column">
              <label *ngIf="a == 0" class="text-14">Degree Awarded<span class="required-field">*</span></label>
              <div class="date-selection-wrapper">
                <fa-icon [icon]="faCaretDown" class="input-date-field-icon" style="left: 109px !important;"></fa-icon>
                <select name="" id="degreeawarded" class="input-text-field wizer-select"
                  style="width: 125px !important;" formControlName="degreeAwarded">
                  <option value="" disabled="disabled" selected>Select Degree</option>
                  <option value="olevel">PhD</option>
                  <option value="msc">MSc</option>
                  <option value="mba">MBA</option>
                  <option value="bsc">BSc</option>
                  <option value="diploma">Diploma</option>
                  <option value="olevel">O'Level</option>
                </select>
              </div>
              <small
                [class.d-none]="educationalQualification.controls[a].get('degreeAwarded')?.valid || educationalQualification.controls[a].get('degreeAwarded')?.untouched"
                class="text-danger">Degree is required</small>
            </div>
            <div class="form-column">
              <label *ngIf="a == 0" class="text-14">Year<span class="required-field">*</span></label>
              <input name="" id="eduyear" class="input-text-field" style="width: 70px !important;"
                formControlName="year" placeholder="Year" min="0" maxlength="4" oninput="this.value = 
                !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
              <div
                [class.d-none]="educationalQualification.controls[a].get('year')?.valid || educationalQualification.controls[a].get('year')?.untouched">
                <small *ngIf="educationalQualification.controls[a].get('year')?.errors?.required"
                  class="text-danger">Year is required</small>
                <ng-container *ngIf="!educationalQualification.controls[a].get('year')?.errors?.required">
                  <small *ngIf="educationalQualification.controls[a].get('year')?.errors?.validateYear"
                    class="text-danger">Invalid Year</small>
                  <!-- <small *ngIf="educationalQualification.controls[a].get('year')?.errors?.minlength"
                    class="text-danger">Year must be minimum of 4 figures</small> -->
                </ng-container>
              </div>
            </div>
          </div>
          <span *ngIf="a != 0" (click)="removeEducationalQualification(a)"><img
              src="../../../assets/images/fi-rr-trash.svg" alt=""></span>
        </div>
      </div>
      <div class="border-bottom-wizer"></div>
      <!-- Professional Qualification-->
      <div class="header-with-button">
        <span class="text-16">Professional Qualification</span>
        <button [disabled]="buttonProQuaDisable"
          [title]="buttonProQuaDisable ? 'You have reached the maximum allowed professional qualifications' : '' "
          (click)="addProfessionalQualification()" type="button" class="form-button">
          <fa-icon [icon]="faPlus" class="icon-spacer"></fa-icon>Add New
        </button>
      </div>

      <div class="label-input-container">
        <div formArrayName="professionalQualification">
          <div class="form-column-container"
            *ngFor="let proQualification of professionalQualification.controls; let j = index;" [formGroupName]="j">
            <div class="field-container-sm">
              <div class="form-column form-column-40">
                <label *ngIf="j == 0" class="text-14">Institution<span class="required-field">*</span></label>
                <input type="text" id="inst-default-1" class="input-text-field" placeholder="Institution"
                  formControlName="institution" />
                <small
                  [class.d-none]="professionalQualification.controls[j].get('institution')?.valid || professionalQualification.controls[j].get('institution')?.untouched"
                  class="text-danger">Institution name is required</small>
              </div>
              <div class="form-column">
                <label *ngIf="j == 0" class="text-14">Certification<span class="required-field">*</span></label>
                <input type="text" class="input-text-field" placeholder="Certification" style="width: 320px !important;"
                  formControlName="certification" />
                <small
                  [class.d-none]="professionalQualification.controls[j].get('certification')?.valid || professionalQualification.controls[j].get('certification')?.untouched"
                  class="text-danger">Certification name is required</small>
              </div>
              <div class="form-column">
                <label *ngIf="j == 0" class="text-14">Year<span class="required-field">*</span></label>
                <input name="" id="profyear" class="input-text-field" style="width: 70px !important;"
                  formControlName="year" placeholder="Year" min="0" maxlength="4" oninput="this.value = 
                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
                <div
                  [class.d-none]="professionalQualification.controls[j].get('year')?.valid || professionalQualification.controls[j].get('year')?.untouched">
                  <small *ngIf="professionalQualification.controls[j].get('year')?.errors?.required"
                    class="text-danger">Year is required</small>
                  <ng-container *ngIf="!professionalQualification.controls[j].get('year')?.errors?.required">
                    <small *ngIf="professionalQualification.controls[j].get('year')?.errors?.validateYear"
                      class="text-danger">Invalid Year</small>
                    <!-- <small *ngIf="professionalQualification.controls[j].get('year')?.errors?.minlength"
                      class="text-danger">Year must be minimum of 4 figures</small> -->
                  </ng-container>
                </div>
              </div>
            </div>
            <span *ngIf="j != 0" (click)="removeProfessionalQualification(j)"><img
                src="../../../assets/images/fi-rr-trash.svg" alt=""></span>
          </div>
        </div>
      </div>
      <div class="border-bottom-wizer"></div>
      <!-- Expertise -->
      <div id="expertise" style="margin-top: 28px">
        <p class="text-16" style="margin-bottom: 21px">Expertise</p>
        <p class="text-14" style="margin-bottom: 28px">
          Please check all relevant boxes that align with your area(s) of
          expertise <span class="required-field">*</span>
        </p>
        <div class="expertise-container" formGroupName="expertise">
          <div id="expertise-first-row">
            <div>
              <input type="checkbox" id="ms-excel" name="office365" value="Microsoft 365 (MS Office)"
                formControlName="office365" />
              <label for="ms-excel" class="text-14">Microsoft 365 (MS Office)</label>
            </div>
            <div>
              <input type="checkbox" id="accounting" name="accounting" value="Accounting"
                formControlName="accounting" />
              <label for="accounting" class="text-14">Accounting</label>
            </div>
            <div>
              <input type="checkbox" id="financial-stmt-analysis" name="fsa" value="Financial Statement Analysis"
                formControlName="financialStatement" />
              <label for="financial-stmt-analysis" class="text-14">Financial Statement Analysis</label>
            </div>
            <div>
              <input type="checkbox" id="power-bi" name="power-bi" value="MS Power BI" formControlName="powerBI" />
              <label for="power-bi" class="text-14">MS Power BI</label>
            </div>
          </div>
          <div id="expertise-second-row">
            <div>
              <input type="checkbox" id="fin-modeling" name="fin-modeling" value="Financial Modelling"
                formControlName="financialModelling" />
              <label for="fin-modeling" class="text-14">Financial Modelling</label>
            </div>
            <div>
              <input type="checkbox" id="business-val" name="business-val" value="Business Valuation"
                formControlName="businessValuation" />
              <label for="business-val" class="text-14">Business Valuation</label>
            </div>
            <div>
              <input type="checkbox" id="project-corperate-fin" name="project-corperate-fin"
                value="Project/Corporate Finance" formControlName="projectCorporateFinance" />
              <label for="project-corperate-fin" class="text-14">Project/Corporate Finance</label>
            </div>
            <div>
              <input type="checkbox" id="macroeconsomic-analysis" name="macroeconomic-analysis"
                value="Macro-economic Analysis" formControlName="macroEconomicAnalysis" />
              <label for="macroeconomic-analysis" class="text-14">Macro-Economic Analysis</label>
            </div>
          </div>
          <small *ngIf="userInfoData.controls['expertise'].errors &&
          userInfoData.controls['expertise'].errors.requireCheckboxesToBeChecked" class="text-danger">At least one
            proficiency is required</small>
        </div>
      </div>
    </div>
    <!-- Captcha -->
    <div style="margin-top: 5px;">
      <re-captcha (resolved)="resolved($event)" siteKey="6LfmmqYnAAAAAA7NmJDMyn7dyJMesO1xXGfBJpFt"></re-captcha>
    </div>
    <!-- Captcha -->
    <div id="home-button-container">
      <button [disabled]="!userInfoData.valid || !isCaptchaSuccesful" type="submit" class="nav-button">
        <span>Next</span>
        <div>
          <img src="../../../assets/images/fi-rr-arrow-small-right.svg" />
        </div>
      </button>
    </div>
  </form>
  <div class="border-bottom-last"></div>
</div>