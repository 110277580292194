<div class="legend-menu">
  <p class="text-15" style="color: var(--wizer-red) !important;">
    Please rate your proficiency with the topics below by selecting the most
    appropriate option.
  </p>

  <div class="text-13 text-color-red legend">
    <span>1 - Not At All</span>
    <span> 2 - Only Slightly</span>
    <span> 3 - Somewhat</span>
    <span> 4 - Proficient</span>
    <span> 5 - Expert</span>
  </div>
</div>

<body class="body-question ">
  <app-header></app-header>
  <div class="content-container">
    <div class="exp-question-content-header">
      <p class="content-header-text" style="margin-top: 40px">
        Adjunct Faculty Application
      </p>
    </div>
    <form class="form-parent-container" #likertForm="ngForm" (ngSubmit)="submitData()">

      <div *ngIf="isLoading" class="d-flex justify-content-center" style="color: var(--wizer-blue);">
        <div class="spinner-border" role="status">
        </div>
      </div>
      <!-- <pre>{{expertiseQuestion.value | json}}</pre> -->
      <!-- {{responseGroup | json}} -->
      <div *ngIf="!isLoading" id="expertise-question-container">
        <ul class="accordion">
          <li *ngFor="let expertiseQuestion of filteredExpertise; let i = index;">
            <input type="checkbox" name="accordion" [id]="i" />
            <div class="accordion-header">
              <label for={{i}}>{{expertiseQuestion.expertiseName}}</label>
              <div class="complete-icon-wrapper">
                <h4 [style.color]="getAnswerPerSubSection(i) === totalSubQuestion(i) ? 'green !important' : 'red' ">
                  {{getAnswerPerSubSection(i)}}/{{totalSubQuestion(i)}} completed</h4>
                <span (click)="openVerticallyCentered(confirmDelete, i, filteredExpertise[i].expertiseName)"><img
                    src="../../../assets/images/fi-rr-trash.svg" alt="dlete-icon"></span>
              </div>
            </div>
            <div class="content">
              <div *ngFor="let questSection of expertiseQuestion.questionSections; let i1 = index;">
                <p *ngIf="expertiseQuestion.questionSections[i1].name !==''" class="text-16-regular sub-heading">
                  {{questSection.name}}
                </p>
                <div *ngFor="let questSubSection of questSection.questionSubSections; let i2 = index;">
                  <div class="question-number-content">
                    <span>{{questSubSection.displayValue === '' ? '' :
                      questSubSection.questionSubSectionNumber+'.'}}</span>
                    <p class="text-14">{{questSubSection.displayValue}}</p>
                  </div>
                  <div *ngFor="let question of questSubSection.questions; let i3 = index ">
                    <div *ngIf="!questSubSection.questions[i3].singleRow">
                      <div class="question-number-content">
                        <span>{{i2+1+'.'}}</span>
                        <p class="text-14">{{question.displayValue}}</p>
                      </div>
                      <div class="likert-container">
                        <div class="question-content">
                          <div class="option-container">
                            <div *ngFor="let option of question.options; let i4 = index">
                              <!-- [(ngModel)]="responseGroup[i].questionSections[i1].questionSubSections[i2].questions[i3].options" -->
                              <input type="radio" [id]="'sheet-choice'+option.id" [name]="'group_'+question.id"
                                [value]="option.id"
                                (change)="setUserResponse(question.id, option.id, i, questSubSection.id, questSubSection.questions.length, i3)"
                                required />
                              <label [for]="'sheet-choice'+i4" style="margin-left: 10px">{{option.displayValue}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="likert-container">
                      <div class="question-content">
                        <div *ngIf="questSubSection.questions[i3].singleRow">
                          <div class="likert-scale-header">
                            <div class="text-section"></div>
                            <div class="radio-container text-14" *ngIf="i3 === 0 ">
                              <span *ngFor="let option of question.options;">{{option.displayValue}}</span>
                            </div>
                          </div>
                          <div class="likert-scale-option">
                            <div class="likert-scale-option-item"
                              [class.likert-scale-option-item-even]="(i3+1) % 2 === 0"
                              [class.likert-scale-option-item-margin-bottom]="i3 === questSubSection.questions.length -1 ">
                              <div class="text-section">
                                <p>{{question.displayValue}}</p>
                              </div>
                              <div class="radio-container">
                                <input *ngFor="let option of question.options; let i5 = index;" type="radio"
                                  [id]="'sheet-choice'+option.id" [name]="'group_'+question.id" [value]="option.id"
                                  (change)="setUserResponse(question.id, option.id, i, questSubSection.id, questSubSection.questions.length, i3)"
                                  required />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <app-back-submit-cancel-button class="nav-button-container-wizer" [isButtonEnabled]="isFormValid()"
      (goBackButton)="goBack()" (cancelButton)="openVerticallyCentered(content)"></app-back-submit-cancel-button> -->
      <div id="back-submit-cancel-button-container">
        <button type="button" (click)="goBack()" class="nav-button-back">
          <div>
            <img src="../../../assets/images/fi-rr-arrow-small-left.svg" />
          </div>
          <span>Back</span>
        </button>

        <!-- [disabled]="isFormValid()" -->
        <button [disabled]="!allQuestionsAnswered" type="submit" class="success-button">Submit</button>

        <button type="button" class="danger-button" (click)="openVerticallyCenteredModal(content)">Cancel</button>
      </div>
    </form>
    <div class="border-bottom-last"></div>
  </div>
</body>
<ng-template #content let-modal>
  <div class="modal-body">
    <div class="center-image"><img src="../../../assets/images/warning-cancel.svg" alt=""></div>
    <p class="text-26 modal-content-body-header-text">Are you sure you want to cancel your application?</p>

    <p class="modal-content-body-text">This process cannot be undone and all the information you have entered will be
      lost. You will need to start the application process from scratch if you decide to reapply.</p>
    <div class="modal-button-container">
      <button type="button" class="danger-button" (click)="modal.close(cancelApplication())">Yes</button>
      <button type="button" class="regular-button" (click)="modal.close('Close click')">No</button>
    </div>
  </div>
</ng-template>

<ng-template #confirmDelete let-modal>
  <div class="modal-body">
    <div class="center-image"><img src="../../../assets/images/warning-cancel.svg" alt=""></div>
    <p class="text-26 modal-content-body-header-text">Are you sure you want to delete this section?</p>

    <p class="modal-content-body-text">This process cannot be undone and {{areaOfExpertise}} will be removed from your
      area(s) of expertise
    </p>
    <div class="modal-button-container">
      <button type="button" class="danger-button" (click)="modal.close('Yes')">Yes</button>
      <button type="button" class="regular-button" (click)="modal.close('Close click')">No</button>
    </div>
  </div>
</ng-template>