<div class="modal-body">
    <div class="center-image"><img src="../../../assets/images/warning-cancel.svg" alt=""></div>
    <p class="text-26 modal-content-body-header-text">Are you sure you want to delete this section?</p>

    <p class="modal-content-body-text">This process cannot be undone and
        {{formName}} will be removed from your area(s) of expertise
    </p>
    <div class="modal-button-container">
        <button type="button" class="danger-button" (click)="confirm()">Yes</button>
        <button type="button" class="regular-button" (click)="cancel()">No</button>
    </div>
</div>