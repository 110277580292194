import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  private formDataKey = 'myFormData';

  constructor() { }

  setFormData(data: any) {
    localStorage.setItem(this.formDataKey, JSON.stringify(data));
  }

  getFormData(): any {
    const storedData = localStorage.getItem(this.formDataKey);
    return storedData ? JSON.parse(storedData) : null;
  }

  clearFormData() {
    localStorage.removeItem(this.formDataKey);
  }

}
