<app-header></app-header>
<div class="content-container">
    <div class="content-header" style="height: 118px;">
        <p class="content-header-text">Adjunct Faculty Application</p>
    </div>

    <div class="submission-body-container">
        <div class="thank-you-message">
            <div id="thank-you-header">
                <img src="../../../assets/images/thank-you-image.svg" alt="thank-you-image">
                <p>Thank You!</p>
            </div>
            <p class="thank-you-text">We appreciate the time and effort you invested in completing this
                form, your application will receive careful consideration and we look forward to exploring the
                possibility of
                working together to foster a vibrant and engaging learning experience.</p>
        </div>
        <div class="home-button-container">
            <button type="button" class="back-to-wizer-home-button"
                onclick="parent.location='https://www.academy.wizerconsulting.com/home' ">
                Visit Wizer Academy</button>
        </div>
    </div>

    <div style="width: 100%; padding: 0rem 10rem 2rem;">
        <div class="border-bottom-submit"></div>
    </div>

</div>