<div id="back-submit-cancel-button-container">
    <button type="button" (click)="goBack()" class="nav-button-back">
        <div>
            <img src="../../../assets/images/fi-rr-arrow-small-left.svg" />
        </div>
        <span>Back</span>
    </button>

    <button [disabled]="isButtonEnabled" type="submit" class="success-button">Submit</button>

    <button type="button" class="danger-button" (click)="cancel()">Cancel</button>
</div>