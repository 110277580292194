import { ValidatorFn, FormGroup, AbstractControl } from '@angular/forms';

export function requireCheckboxesToBeCheckedValidator(
  minRequired = 1
): ValidatorFn {
  return function validate(formGroup: AbstractControl) {
    let checked = 0;

    Object.keys(formGroup.value).forEach((key) => {
      const control = formGroup.value[key];

      if (control === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}
