import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BioData } from 'src/app/dto/bio-data';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/common/constants';
import { FacultyDetails } from 'src/app/dto/faculty-details';
import { QuestionResponse } from 'src/app/dto/question-response';
import { Expertise } from 'src/app/dto/expertise-questions';
import { ResponseData } from 'src/app/dto/response-data';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})

export class DataService {
  private requestURL = environment.baseURL + 'add-new-faculty';
  private facultyRequestURL = environment.baseURL + 'create-faculty-details';
  private questionResponseRequestURL = environment.baseURL + 'expertise-question-response';
  private expertiseQuestionURL = environment.baseURL + 'expertise-qestions';
  private userEmailUrl = environment.baseURL + 'get-user-email';
  private userPhonelUrl = environment.baseURL + 'get-user-phone';

  constructor(private http: HttpClient) { }

  addBioData(bioData: BioData): Observable<any> {
    //console.log('BioData URL->', this.requestURL);
    return this.http.post<BioData>(this.requestURL, bioData, Constants.httpOptions);
  }

  saveFacultyDetails(facultyDetails: FacultyDetails): Observable<any> {
    return this.http.post<FacultyDetails>(this.facultyRequestURL, facultyDetails, Constants.httpOptions);
  }

  saveQuestionResponse(questionResponse: any[]): Observable<any> {
    return this.http.post<any[]>(this.questionResponseRequestURL, questionResponse, Constants.httpOptions);
  }

  fetchExpertiseQuestions(): Observable<Expertise[]> {
    return this.http.get<Expertise[]>(this.expertiseQuestionURL, Constants.httpOptions);
  }

  getUserEmail(email: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(this.userEmailUrl,
      { headers: Constants.httpOptions.headers, params: { email: email } });
  }

  getUserPhone(phoneNumber: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(this.userPhonelUrl,
      { headers: Constants.httpOptions.headers, params: { phoneNumber: phoneNumber } });
  }

}
