import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submission-screen',
  templateUrl: './submission-screen.component.html',
  styles: [],
})
export class SubmissionScreenComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
