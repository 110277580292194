import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertnotifierService } from 'src/app/service/alertnotifier.service';
import {
  faPlus,
  faCalendarDay,
  faTrash,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { requireCheckboxesToBeCheckedValidator } from 'src/app/common/custom-validator';
import {
  checkYear,
  validateStartEndDate,
} from 'src/app/common/year-validator.validator';
import { FormDataService } from 'src/app/service/form-data-storage/form-data.service';
import { of } from 'rxjs';
import { DataService } from 'src/app/service/data-transfer/data-service.service';
import { ResponseData } from 'src/app/dto/response-data';
import { DateFormatterService } from 'src/app/service/date-formatter.service';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styles: [],
})
export class HomeScreenComponent implements OnInit {
  faPlus = faPlus;
  faCalendar = faCalendarDay;
  faTrash = faTrash;
  faCaretDown = faCaretDown;

  userInfoData!: FormGroup;
  wrkExpCounter: number = 0;
  eduQualificationCounter: number = 0;
  profQualificationCounter: number = 0;
  maxNewItem: number = 4;
  buttonWrkExpDisable: boolean = false;
  buttonEduDisable: boolean = false;
  buttonProQuaDisable: boolean = false;
  persistedData?: any;
  firstFieldWrkExpIsPopulated: boolean = false;
  firstFieldEduIsPopulated: boolean = false;
  firstFieldProQuaIsPopulated: boolean = false;
  isCaptchaSuccesful: boolean = false;
  captchaResponse: string = '';

  localDate: Date = new Date();
  formatedDate: string = this.dateFormatterService.formatDate(
    this.localDate.toString()
  );

  minDate = {
    year: 1990,
    month: 1,
    day: 1,
  };
  minEndDate: any;
  minimumEndDate: any[] = [];

  maxDate = {
    year: this.localDate.getFullYear(),
    month: this.localDate.getMonth() + 1,
    day: this.localDate.getDate(),
  };

  // private routerSubscription: Subscription = new Subscription;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastService: AlertnotifierService,
    private formDataService: FormDataService,
    private dataService: DataService,
    private dateFormatterService: DateFormatterService
  ) {}

  // subscribeToRouterEvent() {
  //   this.routerSubscription = this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationStart) {
  //       this.formDataService.setFormData(this.userInfoData.value);
  //     }
  //   });
  // }

  // ngOnDestroy() {
  //   if (this.routerSubscription)
  //     this.routerSubscription.unsubscribe();
  // }

  get fName() {
    return this.userInfoData.get('bioData.firstName');
  }

  get lName() {
    return this.userInfoData.get('bioData.lastName');
  }

  get email() {
    return this.userInfoData.get('bioData.email');
  }

  get telephone() {
    return this.userInfoData.get('bioData.telephone');
  }

  get workExperience(): FormArray {
    return this.userInfoData.get('workExperience') as FormArray;
  }

  get educationalQualification(): FormArray {
    return this.userInfoData.get('educationalQualification') as FormArray;
  }

  get professionalQualification(): FormArray {
    return this.userInfoData.get('professionalQualification') as FormArray;
  }

  addWorkExperience() {
    if (Object.values(this.workExperience.controls).length <= 4) {
      this.workExperience.push(this.getWorkExperienceFields());
      //this.wrkExpCounter++;
    }
    if (Object.values(this.workExperience.controls).length === 5) {
      this.buttonWrkExpDisable = true;
    }
  }

  addEducationalQualification() {
    if (Object.values(this.educationalQualification.controls).length <= 4) {
      this.educationalQualification.push(
        this.getEducationalQualificationFields()
      );
    }
    if (Object.values(this.educationalQualification.controls).length === 5) {
      this.buttonEduDisable = !this.buttonEduDisable;
    }
  }

  addProfessionalQualification() {
    if (Object.values(this.professionalQualification.controls).length <= 4) {
      this.professionalQualification.push(
        this.getProfessionalQualificationFields()
      );
    }
    if (Object.values(this.professionalQualification.controls).length === 5) {
      this.buttonProQuaDisable = !this.buttonProQuaDisable;
    }
  }

  ngOnInit(): void {
    this.persistedData = this.formDataService.getFormData();
    console.log('Home screen data->', this.persistedData);
    this.userInfoData = this.formBuilder.group({
      bioData: this.formBuilder.group({
        firstName: [
          this.persistedData !== null
            ? this.persistedData.bioData.firstName
            : '',
          Validators.required,
        ],
        lastName: [
          this.persistedData !== null
            ? this.persistedData.bioData.lastName
            : '',
          Validators.required,
        ],
        email: [
          this.persistedData !== null ? this.persistedData.bioData.email : '',
          [Validators.required, Validators.email],
        ],
        telephone: [
          this.persistedData !== null
            ? this.persistedData.bioData.telephone
            : '',
          Validators.required,
        ],
      }),
      workExperience: this.formBuilder.array([this.getWorkExperienceFields()]),
      educationalQualification: this.formBuilder.array([
        this.getEducationalQualificationFields(),
      ]),
      professionalQualification: this.formBuilder.array([
        this.getProfessionalQualificationFields(),
      ]),
      expertise: this.formBuilder.group(
        {
          office365: [
            this.persistedData !== null
              ? this.persistedData.expertise.office365
              : '',
          ],
          accounting: [
            this.persistedData !== null
              ? this.persistedData.expertise.accounting
              : '',
          ],
          financialStatement: [
            this.persistedData !== null
              ? this.persistedData.expertise.financialStatement
              : '',
          ],
          powerBI: [
            this.persistedData !== null
              ? this.persistedData.expertise.powerBI
              : '',
          ],
          financialModelling: [
            this.persistedData !== null
              ? this.persistedData.expertise.financialModelling
              : '',
          ],
          businessValuation: [
            this.persistedData !== null
              ? this.persistedData.expertise.businessValuation
              : '',
          ],
          projectCorporateFinance: [
            this.persistedData !== null
              ? this.persistedData.expertise.projectCorporateFinance
              : '',
          ],
          macroEconomicAnalysis: [
            this.persistedData !== null
              ? this.persistedData.expertise.macroEconomicAnalysis
              : '',
          ],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
    });

    this.prefillWorkExperienceField();
    this.prefillEduQualificationField();
    this.prefillProQualificationField();
  }

  setEndDate(index: number) {
    const endDateIndex = this.workExperience.controls[index].get('startDate');
    const newMinDate = this.startDateFormatter(endDateIndex?.value);
    if (this.minimumEndDate[index]) {
      this.minimumEndDate[index] = newMinDate;
    } else {
      this.minimumEndDate.push(newMinDate);
    }
    // console.log('minDate Array->', this.minimumEndDate)
  }

  startDateFormatter(control: any): any {
    const val = control.split('/');
    return {
      year: parseInt(val[2]),
      month: parseInt(val[1]),
      day: parseInt(val[0]),
    };
  }

  getWorkExperienceFields(): FormGroup {
    if (this.persistedData !== null && !this.firstFieldWrkExpIsPopulated) {
      this.firstFieldWrkExpIsPopulated = true;
      const newMinDate = this.startDateFormatter(
        this.persistedData.workExperience[0].startDate
      );
      this.minimumEndDate.push(newMinDate);
      this.wrkExpCounter++;
      return this.formBuilder.group({
        organisation: [
          this.persistedData.workExperience[0].organisation,
          Validators.required,
        ],
        position: [
          this.persistedData.workExperience[0].position,
          Validators.required,
        ],
        startDate: [
          this.persistedData.workExperience[0].startDate,
          Validators.required,
        ],
        endDate: [
          this.persistedData.workExperience[0].endDate,
          [Validators.required, validateStartEndDate('startDate')],
        ],
        current: [this.persistedData.workExperience[0].current],
      });
    } else {
      return this.formBuilder.group({
        organisation: ['', Validators.required],
        position: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', [Validators.required, validateStartEndDate('startDate')]],
        current: ['No'],
      });
    }
  }

  getEducationalQualificationFields(): FormGroup {
    if (this.persistedData !== null && !this.firstFieldEduIsPopulated) {
      this.firstFieldEduIsPopulated = true;
      this.eduQualificationCounter++;
      return this.formBuilder.group({
        institution: [
          this.persistedData.educationalQualification[0].institution,
          Validators.required,
        ],
        discipline: [
          this.persistedData.educationalQualification[0].discipline,
          Validators.required,
        ],
        degreeAwarded: [
          this.persistedData.educationalQualification[0].degreeAwarded,
          Validators.required,
        ],
        year: [
          this.persistedData.educationalQualification[0].year,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            checkYear,
          ],
        ],
      });
    } else {
      return this.formBuilder.group({
        institution: ['', Validators.required],
        discipline: ['', Validators.required],
        degreeAwarded: ['', Validators.required],
        year: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            checkYear,
          ],
        ],
      });
    }
  }

  getProfessionalQualificationFields(): FormGroup {
    if (this.persistedData !== null && !this.firstFieldProQuaIsPopulated) {
      this.firstFieldProQuaIsPopulated = true;
      this.profQualificationCounter++;
      return this.formBuilder.group({
        institution: [
          this.persistedData.professionalQualification[0].institution,
          Validators.required,
        ],
        certification: [
          this.persistedData.professionalQualification[0].certification,
          Validators.required,
        ],
        year: [
          this.persistedData.professionalQualification[0].year,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            checkYear,
          ],
        ],
      });
    } else {
      return this.formBuilder.group({
        institution: ['', Validators.required],
        certification: ['', Validators.required],
        year: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            checkYear,
          ],
        ],
      });
    }
  }

  prefillWorkExperienceField() {
    if (
      this.persistedData !== null &&
      this.persistedData.workExperience.length > 0
    ) {
      const workExperienceData = this.persistedData.workExperience;
      for (let i = 1; i < workExperienceData.length; i++) {
        const formGroup = this.getWorkExperienceFields();
        formGroup.controls['organisation'].setValue(
          workExperienceData[i].organisation
        );
        formGroup.controls['position'].setValue(workExperienceData[i].position);
        formGroup.controls['startDate'].setValue(
          workExperienceData[i].startDate
        );
        formGroup.controls['endDate'].setValue(workExperienceData[i].endDate);
        formGroup.controls['current'].setValue(workExperienceData[i].current);
        if (formGroup.controls['current'].value === 'Yes') {
          formGroup.controls['endDate'].setValue(this.formatedDate, {
            onlySelf: true,
            emitEvent: true,
          });
          formGroup.controls['endDate'].updateValueAndValidity();
          formGroup.controls['endDate'].disable({
            onlySelf: true,
            emitEvent: false,
          });
        }
        if (!workExperienceData[i].hasOwnProperty('endDate')) {
          formGroup.controls['endDate'].setValue(this.formatedDate, {
            onlySelf: true,
            emitEvent: true,
          });
        }
        this.workExperience.push(formGroup);
        const newMinDate = this.startDateFormatter(
          workExperienceData[i].startDate
        );
        this.minimumEndDate.push(newMinDate);
      }
      if (Object.values(this.workExperience.controls).length === 5) {
        this.buttonWrkExpDisable = true;
      }
    }
  }

  prefillEduQualificationField() {
    if (
      this.persistedData !== null &&
      this.persistedData.educationalQualification.length > 0
    ) {
      const eduQualificationData = this.persistedData.educationalQualification;
      for (let i1 = 1; i1 < eduQualificationData.length; i1++) {
        const formGroup = this.getEducationalQualificationFields();
        formGroup.controls['institution'].setValue(
          eduQualificationData[i1].institution
        );
        formGroup.controls['discipline'].setValue(
          eduQualificationData[i1].discipline
        );
        formGroup.controls['degreeAwarded'].setValue(
          eduQualificationData[i1].degreeAwarded
        );
        formGroup.controls['year'].setValue(eduQualificationData[i1].year);
        this.educationalQualification.push(formGroup);
      }
      if (Object.values(this.educationalQualification.controls).length === 5) {
        this.buttonEduDisable = true;
      }
    }
  }
  prefillProQualificationField() {
    if (
      this.persistedData !== null &&
      this.persistedData.professionalQualification.length > 0
    ) {
      const proQualificationData = this.persistedData.professionalQualification;
      for (let i2 = 1; i2 < proQualificationData.length; i2++) {
        const formGroup = this.getProfessionalQualificationFields();
        formGroup.controls['institution'].setValue(
          proQualificationData[i2].institution
        );
        formGroup.controls['certification'].setValue(
          proQualificationData[i2].certification
        );
        formGroup.controls['year'].setValue(proQualificationData[i2].year);
        this.professionalQualification.push(formGroup);
      }
      if (Object.values(this.professionalQualification.controls).length === 5) {
        this.buttonProQuaDisable = true;
      }
    }
  }

  removeWorkExperience(formGroupId: number) {
    this.workExperience.removeAt(formGroupId);
    this.minimumEndDate.splice(formGroupId, 1);
    this.buttonWrkExpDisable = false;
  }

  removeEducationalQualification(formGroupId: number) {
    this.educationalQualification.removeAt(formGroupId);
    this.buttonEduDisable = false;
  }

  removeProfessionalQualification(formGroupId: number) {
    this.professionalQualification.removeAt(formGroupId);
    this.buttonProQuaDisable = false;
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse !== '')
      this.isCaptchaSuccesful = !this.isCaptchaSuccesful;
  }

  checkUserEmail(email: string) {
    of(
      this.dataService.getUserEmail(email).subscribe({
        next: (responseData: ResponseData) => {
          if (responseData) {
            if (responseData.message === 'Email already exists')
              this.email?.setValue('');
            this.toastService.showInfo(
              responseData.responseData,
              responseData.message
            );
          }
        },
        error: (err) =>
          this.toastService.showError(err.message, 'Error Occurred'),
      })
    );
  }

  checkUserPhone(phoneNumber: string) {
    of(
      this.dataService.getUserPhone(phoneNumber).subscribe({
        next: (responseData: ResponseData) => {
          if (responseData) {
            if (responseData.message === 'Phone number already exists')
              this.telephone?.setValue('');
            this.toastService.showInfo(
              responseData.responseData,
              responseData.message
            );
          }
        },
        error: (err) =>
          this.toastService.showError(err.message, 'Error Occurred'),
      })
    );
  }

  goToExpertiseQuestionPage() {
    //this.subscribeToRouterEvent();
    this.formDataService.setFormData(this.userInfoData.value);
    this.router.navigate(['/expertise-question']);
    // this.router.navigate(['/expertise-question'], navigationExtras);
  }

  onRadioChange(index: number) {
    const formArray = this.userInfoData.get('workExperience') as FormArray;
    formArray.controls.forEach((control) => {
      control.get('current')?.patchValue('No');
    });
    const selectedControl = formArray.controls[index];
    selectedControl.get('current')?.setValue('Yes', { emitEvent: false });
    formArray.controls.forEach((control, i) => {
      const endDateTextField = control.get('endDate');

      if (i === index) {
        console.log('formatted date: ', this.formatedDate);
        endDateTextField?.setValue(this.formatedDate, {
          onlySelf: true,
          emitEvent: true,
        });
        endDateTextField?.updateValueAndValidity();
        endDateTextField?.disable({ onlySelf: true, emitEvent: false });
      } else {
        endDateTextField?.enable({ onlySelf: true, emitEvent: false });
      }
    });
  }
}

//https://www.google.com/recaptcha/admin/site/665230054/settings (For captcha)
