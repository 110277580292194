import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-back-submit-cancel-button',
  templateUrl: './back-submit-cancel-button.component.html',
  styles: [],
})
export class BackSubmitCancelButtonComponent implements OnInit {
  @Output() goBackButton = new EventEmitter();
  @Output() cancelButton = new EventEmitter();
  @Input() isButtonEnabled: boolean = true;

  constructor() { }

  ngOnInit(): void { }

  goBack() {
    this.goBackButton.emit();
  }

  cancel() {
    this.cancelButton.emit();
  }
}
