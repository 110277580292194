import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor() { }

  formatDate = (date = "", format = "dayFirst", demacator = "/") => {
    const dateValue = new Date(date)
    const day = `${dateValue.getDate()}`
    const month = `${dateValue.getMonth() + 1}`
    const year = `${dateValue.getFullYear()}`

    if (format === "dayFirst") {
      return `${day}${demacator}${month}${demacator}${year}`
    }
    return `${day}${demacator}${month}${demacator}${year}`
  }
}
