<div class="footer">
  <div class="footer-content-left">
    <div class="powered-by">
      <span>Powered by</span>
      <img src="../../../assets/images/wizer-logo.svg" alt="wizer-logo-small" style="width: 35px; height: 8px" />
    </div>

    <div class="terms-privacy">
      <span>|</span>
      <span>Terms</span>
      <span>Privacy</span>
    </div>
  </div>
  <div class="footer-content-right"><span>&copy; 2023. Wizer Consulting</span></div>
</div>